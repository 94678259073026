import './App.css';
import Spline from '@splinetool/react-spline';
import {useState} from "react";
import {Panel} from "./components";

export default function App() {
    const [isSceneLoaded, setIsSceneLoaded] = useState(false);

    function onLoad() {
        setIsSceneLoaded(true);
    }

    return (
        <div className={'relative h-screen flex justify-center overflow-clip'}>
            <Spline
                scene="https://prod.spline.design/yFvhUDI9M2lUw6Y8/scene.splinecode"
                onLoad={onLoad}
            />
            <Panel/>
            <div
                className={'absolute w-full flex flex-col justify-center h-full items-center top-0 pointer-events-none mix-blend-multiply pb-20 max-w-[2560px] mx-auto'}>
                <div className={'w-fit '}>
                    <h1 className={'text-4xl leading-[60px] md:text-6xl xl:text-[150px] text-red-800 font-bold xl:leading-[150px] drop-shadow-2xl'}>Michael
                        Raisch</h1>
                    <h2 className={'text-xl md:text-2xl xl:text-[40px] text-stone-800 font-mono text-right w-full drop-shadow xl:leading-[50px]'}>Software
                        Engineer<br/>/ Developer<br/>/ Designer</h2>
                </div>
                <div className={'absolute left-0 bottom-0 p-5'}>
                    <button
                        className={'border border-stone-900 text-red-700 mix-blend-multiply rounded-lg p-2 md:p-5 font-mono font-semibold md:text-lg hover:bg-stone-800 hover:text-stone-100 transition'}>
                        About
                    </button>
                </div>
                <div className={'absolute left-0 top-0 p-5'}>
                    <button
                        className={'border border-stone-900 text-red-700 mix-blend-multiply rounded-lg p-2 md:p-5 font-mono font-semibold md:text-lg hover:bg-stone-800 hover:text-stone-100 transition'}>
                        Projects
                    </button>
                </div>
                <div className={'absolute top-0 right-0 p-5'}>
                    <button
                        className={'border border-stone-900 text-red-700 mix-blend-multiply rounded-lg p-2 md:p-5 font-mono font-semibold md:text-lg hover:bg-stone-800 hover:text-stone-100 transition'}>
                        Resume
                    </button>
                </div>
                <div className={'absolute bottom-0 right-0 p-5'}>
                    <button
                        className={'border border-stone-900 text-red-700 mix-blend-multiply rounded-lg p-2 md:p-5 font-mono font-semibold md:text-lg hover:bg-stone-800 hover:text-stone-100 transition'}>
                        Links
                    </button>
                </div>
                {/*<a href={'mailto:michaelthomasraisch@gmail.com'}*/}
                {/*   className={'text-red-700 font-semibold text-sm absolute top-10  underline'}>michaelthomasraisch@gmail.com</a>*/}
            </div>

            {/*<div className={'absolute left-0 w-full max-w-[2560px]'}>*/}
            {/*</div>*/}
        </div>
    );
}


