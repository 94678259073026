import React, { useEffect, useRef, useState } from "react";

export default function Panel() {
    const [height, setHeight] = useState(800);
    const [width, setWidth] = useState(800);
    const [top, setTop] = useState(100);
    const [left, setLeft] = useState(100);
    const resizeRef = useRef();
    const [isResizing, setIsResizing] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [resizeDirection, setResizeDirection] = useState('');

    useEffect(() => {
        function handleResize(e) {
            if (isResizing) {
                const clientX = e.clientX;
                const clientY = e.clientY;
                const rect = resizeRef.current.getBoundingClientRect();
                let newWidth = width;
                let newHeight = height;
                let newTop = top;
                let newLeft = left;

                if (resizeDirection.includes('top')) {
                    newHeight = rect.bottom - clientY;
                    newTop = clientY;
                } else if (resizeDirection.includes('bottom')) {
                    newHeight = clientY - rect.top;
                }

                if (resizeDirection.includes('left')) {
                    newWidth = rect.right - clientX;
                    newLeft = clientX;
                } else if (resizeDirection.includes('right')) {
                    newWidth = clientX - rect.left;
                }

                setWidth(newWidth < 200 ? 200 : newWidth);
                setHeight(newHeight < 200 ? 200 : newHeight);
                setTop(newTop);
                setLeft(newLeft);
            }
        }

        function stopResize() {
            setIsResizing(false);
            setResizeDirection('');
        }

        window.addEventListener('mousemove', handleResize);
        window.addEventListener('mouseup', stopResize);
        return () => {
            window.removeEventListener('mousemove', handleResize);
            window.removeEventListener('mouseup', stopResize);
        };
    }, [isResizing, height, left, resizeDirection, top, width]);

    const startResize = (direction) => {
        setIsResizing(true);
        setResizeDirection(direction);
    };

    const handleDragStart = (e) => {
        setIsDragging(true);
        setDragStart({ x: e.clientX - left, y: e.clientY - top });
    };

    const handleDrag = (e) => {
        if (isDragging && !isResizing) {
            let newLeft = e.clientX - dragStart.x;
            let newTop = e.clientY - dragStart.y;
            newTop = Math.max(0, newTop);
            setLeft(newLeft);
            setTop(newTop);
        }
    }

    const handleDragEnd = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleDrag);
            window.addEventListener('mouseup', handleDragEnd);
        } else {
            window.removeEventListener('mousemove', handleDrag);
            window.removeEventListener('mouseup', handleDragEnd);
        }
        return () => {
            window.removeEventListener('mousemove', handleDrag);
            window.removeEventListener('mouseup', handleDragEnd);
        };
    }, [isDragging]);

    return (
        <div
            ref={resizeRef}
            style={{
                height: height,
                width: width,
                top: top + 'px',
                left: left + 'px',
                position: 'absolute',
            }}
            className={'bg-stone-50 z-50 rounded-xl bg-opacity-70   shadow'}

        >
            <div
                id={'top-bar'}
                className={'w-full p-2 bg-stone-600 cursor-move'}
            >
                <div className={'h-6 w-full'} onMouseDown={handleDragStart}></div>
            </div>
            <div
                className={'resize-handle top-left cursor-nw-resize'}
                onMouseDown={() => startResize('top-left')}
            ></div>
            <div
                className={'resize-handle top-right cursor-ne-resize'}
                onMouseDown={() => startResize('top-right')}
            ></div>
            <div
                className={'resize-handle bottom-left cursor-ne-resize'}
                onMouseDown={() => startResize('bottom-left')}
            ></div>
            <div
                className={'resize-handle bottom-right cursor-nw-resize'}
                onMouseDown={() => startResize('bottom-right')}
            ></div>
        </div>
    );
}
